import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import PageTitle from "components/PageTitle";
import Tabs from "components/Tabs";
import OrderCard from "components/OrderCard";
import TagsAutocomplete from "components/TagsAutocomplete";
import Autocomplete from "components/Autocomplete";
import Pagination from "components/Pagination";
import { useFetch } from "hooks/useFetch";
import SearchTextFieldInput from "components/SearchTextFieldInput";
import CreateReportDialog from "pages/orders/CreateReport";
import {
  TABS_TITLES,
  QUICK_FILTER_OPTIONS,
  defined,
  ready,
  inProduction,
} from "pages/orders/data";
import { usePost } from "hooks/usePost";
import { setTab } from "features/orders/ordersTabsSlice";
import { setAppVersion } from "features/appVersion/appVersionSlice";
import {
  CONFLUENCE_ORDERS,
  DECORATIONS,
  APP_VERSION,
} from "constants/services";
import { ORDER } from "constants/routes";
import {
  StyledContainer,
  NoDataFound,
  Filter,
  OrderDetails,
  QuickFilters,
} from "styles/pages/orders";
import { CSR_ROLE, DEVELOPER_ROLE } from "constants/roles";
import InformStockStatusDialog from "pages/orders/InformStockStatus";

const take = 8;

const Orders = () => {
  const csvEl = useRef(null);
  const { selectedTabIndex, page, search } = useSelector((s) => s?.ordersTabs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutateAsync } = usePost();
  const [state, setState] = useState();
  const [csvData, setCsvData] = useState();
  const [fileName, setFileName] = useState();
  const appVersion = useSelector((s) => s?.appVersion?.version);
  const { refetch } = useFetch(APP_VERSION, APP_VERSION, { enabled: false });
  const user = useSelector((s) => s?.user);

  const compareVersion = async () => {
    const { data } = await refetch();
    if (data && data?.CurrentAppVersion !== appVersion) {
      dispatch(setAppVersion({ version: data?.CurrentAppVersion }));
      window.location.reload();
    }
  };

  useEffect(() => {
    compareVersion();
    return () => true;
  }, [appVersion]);

  const fetchOrders = async () => {
    const { data: orders } = await mutateAsync({
      url: CONFLUENCE_ORDERS,
      data: {
        take,
        skip: (page - 1) * take,
        status: search ? null : TABS_TITLES[selectedTabIndex]?.value,
        search: search || null,
        decoration: search ? null : state?.decoration?.value || null,
        artProofStatus: state?.quickFilter ? state?.quickFilter?.id : null,
      },
    });
    setState({
      ...state,
      orders,
      decoration: search ? null : state?.decoration,
    });
  };

  useEffect(() => {
    fetchOrders();
  }, [page, selectedTabIndex, state?.decoration, state?.quickFilter]);

  const buttons = [
    {
      label: "Create Report",
      onClick: () => setState({ ...state, createReport: true }),
    },
  ];

  if (
    (selectedTabIndex === 0 || selectedTabIndex === 1) &&
    (user?.role === DEVELOPER_ROLE.role || user?.role === CSR_ROLE.role)
  ) {
    buttons.push({
      label: "Inform Stock Status",
      onClick: () => setState({ ...state, informStockStatus: true }),
    });
  }

  return (
    <StyledContainer fixed>
      <PageTitle title="BOT TruTrac App" createOrder buttons={buttons} />
      <Filter>
        <SearchTextFieldInput
          label="Search Order"
          value={search}
          handleChange={(v) =>
            dispatch(setTab({ selectedTabIndex, page, search: v }))
          }
          handleSubmit={() => {
            if (page === 1) {
              fetchOrders();
            } else {
              dispatch(setTab({ selectedTabIndex, page: 1 }));
            }
          }}
        />

        <TagsAutocomplete
          multiple={false}
          optionApiKey="decoCode"
          apiEndPoint={DECORATIONS}
          value={state?.decoration || { title: "", value: "" }}
          setValue={(decoration) => {
            if (search) {
              setState({ ...state, decoration });
              dispatch(setTab({ selectedTabIndex, page, search: "" }));
            } else {
              setState({ ...state, decoration });
            }
          }}
          label="Decorations"
        />
      </Filter>
      <Tabs
        value={selectedTabIndex}
        disabled={!!search}
        tabs={TABS_TITLES?.map((t) => t?.title)}
        onTabChange={(v) => {
          setState({
            ...state,
            quickFilter: undefined,
            quickFilterInputValue: undefined,
          });
          dispatch(setTab({ selectedTabIndex: v, page: 1 }));
        }}
      >
        <OrderDetails>
          {TABS_TITLES[selectedTabIndex]?.value === defined?.value ||
          TABS_TITLES[selectedTabIndex]?.value === ready?.value ||
          TABS_TITLES[selectedTabIndex]?.value === inProduction?.value ? (
            <QuickFilters>
              <Autocomplete
                label="Quick Filter"
                options={QUICK_FILTER_OPTIONS}
                value={state?.quickFilter}
                setValue={(v) => setState({ ...state, quickFilter: v })}
                inputValue={state?.quickFilterInputValue}
                setInputValue={(v) =>
                  setState({ ...state, quickFilterInputValue: v })
                }
              />
            </QuickFilters>
          ) : (
            <QuickFilters />
          )}

          {state?.orders?.data?.length > 0 && (
            <Pagination
              count={Math.ceil(state.orders.totalCount / take)}
              page={page}
              handleChange={(e, v) =>
                dispatch(setTab({ selectedTabIndex, page: v }))
              }
            />
          )}
        </OrderDetails>

        <Grid container spacing={3} sx={{ marginTop: "1px" }}>
          {state?.orders?.data?.map((o) => (
            <Grid item xs={3} key={`${o?.orderNumber}_${o?.vendorName}`}>
              <OrderCard
                data={o}
                refresh={fetchOrders}
                handleClick={() =>
                  navigate(`${ORDER}/${o?.orderNumber}/${o?.vendorName}`)
                }
              />
            </Grid>
          ))}
        </Grid>

        {!state?.orders?.data?.length && (
          <NoDataFound>No data found !</NoDataFound>
        )}
      </Tabs>
      {state?.createReport && (
        <CreateReportDialog
          open={state?.createReport}
          onClose={() => setState({ ...state, createReport: false })}
          onCreate={async (payload) => {
            setFileName(
              `${payload?.vendorName}(${moment
                .unix(payload?.startDate)
                .format("MMM DD, YYYY")}-${moment
                .unix(payload?.endDate)
                .format("MMM DD, YYYY")})${
                payload?.status ? `[${payload?.status}]` : ""
              }.csv`
            );
            const { data } = await mutateAsync({
              url: CONFLUENCE_ORDERS,
              data: payload,
            });

            if (data?.length > 0) {
              const rows = [
                [
                  "Order_Number",
                  "Vendor_Name",
                  "Client_Emails",
                  "Decorations",
                  "Artproof Approval",
                  "Print_Provider",
                  "Order_Created",
                  "Check_In_Dates",
                  "Requested_Ship_Date",
                  "Status",
                  "Shipped Date",
                  "Tracking Details",
                  "Total Peice Count",
                ],
              ];

              for (let i = 0; i < data.length; i += 1) {
                const {
                  orderNumber,
                  vendorName,
                  clientEmails,
                  decorations,
                  artProof,
                  printProvider,
                  createdDate,
                  status,
                  trackingDetails,
                  shippedDate,
                  orderCheckInData,
                  requestedShipDate,
                  totalPeiceCount,
                } = data[i];
                rows.push([
                  orderNumber,
                  vendorName,
                  clientEmails?.join(),
                  decorations?.join(),
                  artProof?.decision?.toUpperCase(),
                  printProvider,
                  moment.unix(createdDate).format("MMM DD, YYYY"),
                  orderCheckInData
                    ?.map(
                      (o) =>
                        `${o?.stockStatus} (${moment
                          .unix(o?.timeStamp)
                          .format("MMM DD, YYYY")})`
                    )
                    .join(", ") || "NA",
                  requestedShipDate
                    ? moment.unix(requestedShipDate).format("MMM DD, YYYY")
                    : "NA",
                  status,
                  shippedDate
                    ? moment.unix(shippedDate).format("MMM DD, YYYY")
                    : "-",
                  trackingDetails
                    ? trackingDetails?.map((t) => t?.trackingNumber)?.join()
                    : "-",
                  totalPeiceCount || "",
                ]);
              }

              setCsvData(rows);
              setTimeout(() => {
                csvEl.current.link.click();
              }, 10);
            } else {
              // eslint-disable-next-line
              alert("No data found!");
            }
          }}
        />
      )}

      {state?.informStockStatus && (
        <InformStockStatusDialog
          open={state?.informStockStatus}
          onClose={() => setState({ ...state, informStockStatus: false })}
        />
      )}

      {csvData && <CSVLink data={csvData} ref={csvEl} filename={fileName} />}
    </StyledContainer>
  );
};

export default Orders;
