/* eslint-disable no-restricted-globals */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useFetch } from "hooks/useFetch";
import { usePost } from "hooks/usePost";
import { STOCK_STATUS, INFORM_STOCK_STATUS } from "constants/services";
import { CreateOrderWrapper, CreateOrderRow } from "styles/pages/orders";
import moment from "moment";
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InformClientProgressDialog from "pages/orders/InformClientProgressDialog";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const InformStockStatusDialog = ({ open, onClose }) => {
  const { mutateAsync } = usePost();
  const [state, setState] = useState();
  const { refetch: refetchVendors } = useFetch(STOCK_STATUS, STOCK_STATUS, {
    enabled: false,
  });
  const orders = state?.ordersData;

  const fetchVendors = async () => {
    const { data } = await refetchVendors();

    if (data) {
      setState(data);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const informClients = async () => {
    if (
      confirm(
        `It will take approx. ${
          (state.ordersData.length * 3) / 60
        } minutes to complete.\nAre you want to proceed?`
      )
    ) {
      setState({ ...state, showProgressDialog: true, clientNotifiedCount: 0 });

      for await (const order of orders) {
        await mutateAsync({
          url: INFORM_STOCK_STATUS,
          data: {
            clientEmails: order?.clientEmails,
            orderNumber: order?.orderNumber,
          },
        });

        setState((s) => ({
          ...s,
          showProgressDialog: true,
          clientNotifiedCount: s.clientNotifiedCount + 1,
        }));

        await sleep(2000);
      }

      await mutateAsync({
        url: STOCK_STATUS,
        isPatch: true,
        data: {
          key: 1733414550,
          lastUpdate: moment().unix(),
        },
      });

      setState();
      onClose();

      alert("Clients notified successfully!");
    }
  };

  return (
    <>
      <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
        <DialogTitle>
          Inform Stock Received Status
          {state?.lastUpdate && (
            <CreateOrderRow>
              <div style={{ fontSize: "16px" }}>
                Last Informed:&nbsp;
                <b>
                  {moment.unix(state?.lastUpdate).format("MMM DD, YYYY")}&nbsp;[
                  {moment().diff(moment.unix(state?.lastUpdate), "days")} day(s)
                  ago]
                </b>
              </div>
            </CreateOrderRow>
          )}
        </DialogTitle>

        <DialogContent dividers>
          <CreateOrderWrapper style={{ width: "100%" }}>
            {orders?.length > 0 ? (
              <TableContainer sx={{ marginTop: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Order Number</TableCell>
                      <TableCell>Vendor</TableCell>
                      <TableCell>Client Emails</TableCell>
                      <TableCell>Stock Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((row) => (
                      <TableRow key={row.requestId}>
                        <TableCell>{row?.orderNumber}</TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: "200px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {row.vendorName}
                          </div>
                        </TableCell>
                        <TableCell>
                          {row?.clientEmails?.map((e) => (
                            <Chip key={e} label={e} sx={{ marginBottom: 1 }} />
                          ))}
                        </TableCell>
                        <TableCell>
                          {row?.orderCheckInData?.length > 0
                            ? `${
                                row.orderCheckInData[
                                  row.orderCheckInData.length - 1
                                ].stockStatus
                              } Stock Received`
                            : "No Stock Received"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </CreateOrderWrapper>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={informClients}>
            Inform Clients
          </Button>
        </DialogActions>
      </Dialog>
      {state?.showProgressDialog && (
        <InformClientProgressDialog
          open={state?.showProgressDialog}
          state={state}
        />
      )}
    </>
  );
};

InformStockStatusDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

InformStockStatusDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

export default InformStockStatusDialog;
