/* eslint-disable react/prop-types */
import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Dialog } from "@mui/material";

const InformClientProgressDialog = ({ open, state }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <Box sx={{ background: "white", padding: "20px" }}>
        <h3 style={{ margin: "0 0 20px" }}>
          Client Notified {state?.clientNotifiedCount}/
          {state?.ordersData?.length}
        </h3>

        <LinearProgress
          variant="buffer"
          value={state?.clientNotifiedCount}
          valueBuffer={state.clientNotifiedCount + 10}
        />
        <h3 style={{ margin: "20px 0 0 0" }}>
          Estimated time to complete:&nbsp;
          {(state.ordersData.length * 3) / 60} minutes
        </h3>

        <h4 style={{ margin: "10px 0 0", color: "red", fontSize: "12px" }}>
          Note: Please don&apos;t close or reload the window, it might stop the
          notification queue
        </h4>
      </Box>
    </Dialog>
  );
};

export default InformClientProgressDialog;
