import styled from "styled-components";
import Button from "components/LoadingButton";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const Container = styled.div`
  border-bottom: 1px solid #000;
  padding-bottom: 2px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTitle = styled(Typography)``;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledAddCircleIcon = styled(AddCircleIcon)`
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
  }
`;
